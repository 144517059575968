<template>
	<MhRouterView class="OvBlogPostView view background2--color-primary-background" :isContentReady="isContentReady">

		<!-- default case -->
		<div class="minHeight minHeight--100 vSpace vSpace--viewContent" v-if="!is404">

			<OvMainHeadline
				:post="post"
			></OvMainHeadline>

			<OvSpacer type="default" :rows="6"></OvSpacer>
			<OvContentModules :post="post"></OvContentModules>

		</div>

		<!-- 404 case -->
		<div class="minHeight minHeight--100 vSpace vSpace--viewContent" v-else>

			<Ov404></Ov404>

		</div>

		<OvMainFooter></OvMainFooter>

		<!--
		<pre data-name="post">{{post}}</pre>
		-->

	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js' // TODO: remove this
	import RestHandler from '@/components/RestHandler/RestHandler.js'

	import MhRouterView from '@/components/MhRouterView/MhRouterView.vue'
	import OvMainHeader from '@/components/OvMainHeader.vue'
	import OvMainHeadline from '@/components/OvMainHeadline.vue'
	import OvContentModules from '@/components/OvContentModules.vue'
	import OvMainFooter from '@/components/OvMainFooter.vue'
	import OvSpacer from '@/components/OvSpacer.vue'
	import Ov404 from '@/components/Ov404.vue'

	export default {
		name: 'OvBlogPostView',
		components: {
			MhRouterView,
			OvMainHeader,
			OvMainHeadline,
			OvContentModules,
			OvMainFooter,
			OvSpacer,
			Ov404,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {},
		data() {
			return {
				post: {},
				isContentReady : false,
				is404 : false,
			}
		},
		watch: {
			restHandler__pendingRequests: {
				handler( to, from, doLog = false ) {
					const icon = to ? '❌' : '✅'

					if( doLog ){
						console.groupCollapsed(this.$options.name, '• watch restHandler__pendingRequests', from, to, icon)
						console.groupEnd()
					}

					if( !to ) this.isContentReady = true
				},
				immediate : true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
		},
		methods: {
			fetchPost( doLog = false ){
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						slug : this.$route.params.slug
					},
					callback : (response) => {
						const result = response.data.result[0]

						this.post = result
						this.is404 = result ? false : true
					},
				})
			},
		},
		mounted() {
			this.fetchPost()
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";

	.OvBlogPostView {
		//background-color: yellow;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
